import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "./imxLoggerservices";

async function changeImxLoggerAppName(params = {}) {
  try {
    const transactionNodeService =
      services.NODE_ENDPOINT_CHANGE_APP_NAME_IMXLOGGER;

    const res = await axiosProviderNode.get(
      transactionNodeService + params?.appName,
      params
    );
    return res?.data;
  } catch (error) {
    throw error;
  }
}
export default changeImxLoggerAppName;
