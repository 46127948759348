import { useState, useEffect } from "react";
import io from "socket.io-client";
import { getMaximoConfig } from "../installer/services";
const nameSpace = "/systemMonitor";

export function useMonitorSocket() {
  const [componentData, setComponentData] = useState(null);
  const [socketStatus, setSocketStatus] = useState(false);
  const [socket, setSocket] = useState(null);
  function startSocket(callback = () => {}) {
    try {
      socket?.connect();
      socket?.on("connect", () => {
        socket.emit("getUpdates", {});
      });
      callback(true);
    } catch (error) {
      console.error("socket connect error :", error);
    }
  }
  function stopSocket(callback = () => {}) {
    try {
      socket?.disconnect();
      socket?.close();
      callback(false);
    } catch (error) {
      console.error("socket disconnect error :", error);
    }
  }
  socket?.on("monitorUpdates", (data) => {
    setComponentData(data);
  });
  socket?.on("connect_error", () => {
    setSocketStatus(false);
    socket.disconnect();
  });
  useEffect(() => {
    (async () => {
      const { socketAddress, protocol } = await getMaximoConfig().then(
        (res) => res?.content?.server
      );
      const { hostname: windowaddress } = window.location;
      setSocket(
        io(
          `${
            socketAddress ? `${protocol}://${socketAddress}` : windowaddress
          }${nameSpace}`,
          {
            rejectUnauthorized: false,
          }
        )
      );
    })();
  }, []);

  useEffect(() => {
    !socketStatus && socket && startSocket(setSocketStatus);

    return () => {
      stopSocket(setSocketStatus);
    };
  }, [socket]);
  useEffect(() => {
    const refreshInterval = setInterval(async () => {
      if (!socketStatus) {
        const MonitorResult = await getMaximoConfig().then(
          (res) => res?.content
        );
        setComponentData(MonitorResult);
      }
    }, 10000);
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return componentData;
}
