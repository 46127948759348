import "./index.scss";

import { useState, useEffect } from "react";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { useLocation } from "react-router-dom";

import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";

import Icon from "../../../designer/components/icon";
import { sideBarItemsList } from "./sidebar-entries";

function Sidebar(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const location = useLocation();
  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  let navigate = useNavigate();

  useEffect(() => {
    if (props?.children?.props?.className === "monitorBody") {
      navigate("/monitor/Dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  useEffect(() => {
    const activeItem = sideBarItemsList.find((item) =>
      location.pathname.includes(item?.name)
    );
    setActiveMenu(activeItem?.display ?? "Dashboard");
  }, [location]);
  return (
    <div className="monitorSideBarr">
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        onToggle={handleToggleSidebar}
        breakPoint="md"
      >
        <SidebarHeader>
          <Menu iconShape="circle">
            {collapsed ? (
              <MenuItem
                icon={<FaAngleDoubleRight />}
                onClick={handleCollapsedChange}
              ></MenuItem>
            ) : (
              <MenuItem
                suffix={<FaAngleDoubleLeft />}
                onClick={handleCollapsedChange}
              ></MenuItem>
            )}
          </Menu>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            {sideBarItemsList.map((sideItem, index) => (
              <MenuItem
                key={index}
                icon={<Icon size={30} color="#4b4b4d" icon={sideItem.icon} />}
                className={`menu-item ${
                  activeMenu === sideItem.name ? "active-menu-item" : ""
                }`}
              >
                {sideItem.name}

                <NavLink to={`/monitor/${sideItem?.name}`} />
              </MenuItem>
            ))}
          </Menu>
        </SidebarContent>
      </ProSidebar>
      <div className="dashboard-body">{props.children}</div>
    </div>
  );
}
export default Sidebar;
