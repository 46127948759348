import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "./imxLoggerservices";

async function clearLocalLogsByName(params = {}) {
  try {
    const transactionNodeService =
      services.NODE_ENDPOINT_CLEAR_LOCAL_LOGS_FILES_IMXLOGGER +
      params?.fileName;
    const res = await axiosProviderNode.get(transactionNodeService);
    return res?.data;
  } catch (error) {
    throw error;
  }
}
export default clearLocalLogsByName;
