import React, { useEffect, useState, useMemo, useCallback, memo } from "react";
import { Chart } from "react-google-charts";
import { getRamUsage } from "../../../../installer/services";
import Select from "react-select";
import { getTransactionsData } from "../../../../installer/services/getTransactions/getTransactions";
import Sidebar from "../../monitor-sidebar";
import DashboardApp from "../../../../dashboard/Dashboard";

const options = {
  chart: {
    title: "RAM Usage Realtime",
  },
  series: {
    0: { axis: "RAM" },
  },
  axes: {
    y: {
      RAM: { label: "RAM (GB)" },
    },
  },
};
const selectOptions = [
  { value: 1, label: "1 day ago" },
  { value: 2, label: "2 days ago" },
  { value: 3, label: "3 days ago" },
  { value: 4, label: "4 days ago" },
  { value: 5, label: "5 days ago" },
  { value: 6, label: "6 days ago" },
  { value: 7, label: "week ago" },
];

const columns = [
  { type: "string", id: "User" },
  { type: "string", id: "Service" },
  { type: "string", role: "tooltip" },
  { type: "date", id: "Start" },
  { type: "date", id: "End" },
];

function RamChart() {
  const [lineChartData, setLineChartData] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [query, setQuery] = useState(selectOptions[0]);
  const RamChartData = useMemo(() => {
    return lineChartData?.reduce(
      (acc, elem) => {
        if (elem.RAM && elem.createdAt) {
          acc.push([new Date(elem.createdAt), elem.RAM]);
        }
        return acc;
      },
      [[{ type: "date", label: "Date" }, "RAM Usage"]]
    );
  }, [lineChartData]);

  const getRamUsageData = useCallback(async (value) => {
    const lineChartData = await getRamUsage({ d: value });
    setLineChartData(lineChartData.content.result);
  }, []);

  const getTransactions = useCallback(async () => {
    const transactionsData = await getTransactionsData();
    const row = transactionsData.content.result.reduce((acc, val) => {
      if (val?.transaction) {
        const trx = val.transaction;
        acc.push([
          trx.login,
          trx.service,
          JSON.stringify(trx),
          new Date(trx.timeStamp),
          new Date(trx.timeStamp + 5),
        ]);
        return acc;
      }
      return acc;
    }, []);
    setTimelineData([columns, ...row]);
  }, []);

  const HandleOnChange = useCallback((event) => {
    setQuery(event);
  }, []);

  useEffect(() => {
    getRamUsageData(query.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardApp>
      <Sidebar>
        <div>
          {RamChartData.length === 1 ? (
            <>Loading chart...</>
          ) : (
            <>
              <Select
                options={selectOptions}
                onChange={HandleOnChange}
                defaultValue={query}
              />

              <Chart
                chartType="Line"
                width="100%"
                height="400px"
                data={RamChartData}
                options={options}
                chartPackages={["corechart", "controls"]}
                controls={[
                  {
                    controlType: "ChartRangeFilter",
                    options: {
                      filterColumnIndex: 0,
                      ui: {
                        chartType: "LineChart",
                        chartOptions: {
                          chartArea: { width: "95%", height: "50%" },
                          hAxis: { baselineColor: "none" },
                        },
                      },
                    },
                    controlPosition: "bottom",
                    controlWrapperParams: {
                      state: {
                        range: {
                          start: new Date(
                            Date.now() - query.value * 24 * 3600 * 1000
                          ),
                          end: new Date(),
                        },
                      },
                    },
                  },
                ]}
              />
            </>
          )}

          {RamChartData.length === 1 ? (
            <div style={{ marginTop: 200 }}>
              <>Loading chart...</>
            </div>
          ) : (
            <div style={{ marginTop: 200 }}>
              <Chart
                chartType="Timeline"
                data={timelineData}
                width="95%"
                height="400px"
                controls={[
                  {
                    controlType: "StringFilter",
                    options: {
                      filterColumnIndex: 0,
                      matchType: "any", // 'prefix' | 'exact',
                      ui: {
                        label: "Search by name",
                      },
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
      </Sidebar>
    </DashboardApp>
  );
}
export default memo(RamChart);
