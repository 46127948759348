import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "./imxLoggerservices";

async function disconnectImxLogger(params = {}) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_DISCONNECT_IMXLOGGER;

    const res = await axiosProviderNode.get(transactionNodeService, params);
    return res?.data;
  } catch (error) {
    throw error;
  }
}
export default disconnectImxLogger;
