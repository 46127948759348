import axios from "axios";

const instance = axios.create();
instance.defaults.timeout = 120000;

instance.interceptors.response.use(
  (response) => {
    try {
      const { authorization } = response.headers;
      if (authorization) {
        localStorage.setItem("authorization", `Bearer: ${authorization}`);
      }
      if (authorization === null) {
        localStorage.removeItem("authorization");
      }

      const response_status = response?.status;
      if (response_status === 401) {
        localStorage.removeItem("authorization");
        window.location = `/`;
      }

      return response;
    } catch (error) {
      console.error("Axios response error", error);
      return Promise.reject(error);
    }
  },
  (error) => {
    console.error("Axios response error", error);
    const response_status = error?.response?.status;

    if (response_status === 401) {
      localStorage.removeItem("authorization");
      window.location = `/`;
    }

    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  const authorization = localStorage.getItem("authorization");
  if (authorization) {
    config.headers.Authorization = authorization;
  }
  return config;
});

const setDefaultHeaders = (accessToken) => {
  instance.defaults.headers.common["Authorization"] = accessToken;
};

const getDefaultHeaders = () => {
  return instance.defaults.headers;
};

let cancelTokenSource = axios.CancelToken.source();

const axiosCancel = () => {
  const resetCancelToken = () => {
    cancelTokenSource.cancel("HTTP Request canceled");
    cancelTokenSource = axios.CancelToken.source();
  };
  const getCancelToken = () => {
    return cancelTokenSource.token;
  };
  return {
    resetCancelToken,
    getCancelToken,
  };
};

const CancelHTTPRequest = () => {
  axiosCancel().resetCancelToken();
};

const axiosClient = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  setDefaultHeaders,
  getDefaultHeaders,
  axiosCancel,
  CancelHTTPRequest,
};

export default axiosClient;
