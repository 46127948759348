import "./index.scss";
import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../monitor-sidebar";
import DashboardApp from "../../../dashboard/Dashboard";
import ReactJson from "react-json-view";
import getenvConfData from "./modules/getenvConfData";
import setenvConfDataWithParams from "./modules/setenvConfData";
import { useToasts } from "react-toast-notifications";

function Configuration() {
  const [envConfData, setEnvConfData] = useState({});
  const { addToast } = useToasts();

  const setenvconfValues = async () => {
    try {
      await setenvConfDataWithParams({
        newEnvconf: envConfData,
      });
      addToast("Configuration saved successfully", {
        appearance: "success",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    } catch (error) {
      console.error("error ===>", error);
      addToast("error while saving configuration", {
        appearance: "error",
        autoDismissTimeout: 3000,
        autoDismiss: true,
        transitionDuration: 600,
      });
    }
  };

  const getenvconfValues = useCallback(async () => {
    try {
      const envConfDataResponse = await getenvConfData();
      setEnvConfData(envConfDataResponse?.content);
    } catch (error) {
      console.error("error ===>", error);
    }
  }, []);

  useEffect(() => {
    getenvconfValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardApp>
      <Sidebar>
        <div className="buttons-container">
          <div className="p-3" data-testid="IssueInfoCard">
            <div>
              <button onClick={setenvconfValues}>Save Changes</button>
            </div>
          </div>
          <div className="p-3">
            <button onClick={getenvconfValues}>Refresh</button>
          </div>
        </div>
        <div className="d-flex p-2">
          <ReactJson
            src={envConfData}
            onEdit={(values) => {
              setEnvConfData(values.updated_src);
            }}
            onDelete={(values) => {
              setEnvConfData(values.updated_src);
            }}
            onAdd={(values) => {
              setEnvConfData(values.updated_src);
            }}
          />
        </div>
      </Sidebar>
    </DashboardApp>
  );
}

export default Configuration;
