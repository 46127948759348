import { axiosProviderNode } from "../../../../http/httpService";
import { services } from "./imxLoggerservices";

async function geteImxLoggEnvConfData(params = {}) {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_GET_ENVCONF;

    const res = await axiosProviderNode.get(transactionNodeService, params);
    return res?.data;
  } catch (error) {
    throw error;
  }
}
export default geteImxLoggEnvConfData;
