import Configuration from "../configuration";
import Dashboard from "../dashboard";
import RamChart from "../dashboard/ramChart";
import DebugIndex from "../debug";
import ImxLogger from "../imxLogger";
import Issues from "../issues";
import { LogsReader } from "../LogsReader";
import Performances from "../performances";

export const sideBarItemsList = [
  {
    name: "Dashboard",
    icon: "monitoring",
    display: "Dashboard",
    component: Dashboard,
  },
  { name: "Issues", icon: "monitoring", display: "Issues", component: Issues },
  {
    name: "Performances",
    icon: "monitoring",
    display: "Performances",
    component: Performances,
  },
  {
    name: "Debug",
    icon: "monitoring",
    display: "Debug",
    component: DebugIndex,
  },
  {
    name: "LogsReader",
    icon: "monitoring",
    display: "LogsReader",
    component: LogsReader,
  },
  {
    name: "Stats",
    icon: "monitoring",
    display: "Stats",
    component: RamChart,
  },
  {
    name: "Configuration",
    icon: "monitoring",
    display: "Configuration",
    component: Configuration,
  },

  {
    name: "ImxLogger",
    icon: "monitoring",
    display: "ImxLogger",
    component: ImxLogger,
  },
];
