import "./index.scss";

import React, { useState, useEffect } from "react";

import { useStore, useSelector } from "react-redux";

import { useToasts } from "react-toast-notifications";

import Editor from "./editor";

import Icon from "../../icon";

function EditPane() {
  let store;
  store = useStore();

  const { addToast } = useToasts();

  const [updatedValues, setUpdatedValues] = useState("");
  const [objectValues, setObjectvalues] = useState({});

  let edit = useSelector((state) =>
    state?.entities?.editor ? state.entities.editor : {}
  );

  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  const {
    dataToSet: data,
    parentToEdit: parent,
    component: componentEdited,
  } = edit;

  useEffect(() => {
    setUpdatedValues("");
  }, [edit]);

  useEffect(() => {
    setObjectvalues({});
    let resObjectValues = {};

    data &&
      Object.keys(data)?.forEach((key) => {
        if (typeof data[key] === "object") {
          resObjectValues = { ...resObjectValues, [key]: data[key] };
          setObjectvalues(resObjectValues);
        }
      });
  }, [data]);

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue =
      "You have unsaved changes, Are you sure you want to exit ? ");
  };

  useEffect(() => {
    if (!readOnly) {
      window.addEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
    }
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
    };
  }, [readOnly]);

  const handleSubmit = () => {
    store.dispatch({
      type: "validation",
      paylaod: {
        updatedValues,
        parent,
        objectValues,
        addToast,
        componentEdited,
      },
    });
    setUpdatedValues("");
  };

  return (
    <div id="EditPane" className="EditPane" data-testid="EditPane">
      <div
        className={
          updatedValues
            ? "bar-title d-flex flex-row update-displayed"
            : "bar-title d-flex flex-row "
        }
      >
        <span className="propertiesTitle">PROPRERTIES</span>

        {updatedValues !== "" && !readOnly && (
          <div className="submit-editor">
            <button onClick={handleSubmit} className="submit-btn">
              <Icon icon="save" size={14} color="#f5b939" />
            </button>
          </div>
        )}
      </div>

      <Editor
        setUpdatedValues={setUpdatedValues}
        data={data}
        readOnly={readOnly}
      />
    </div>
  );
}

export default EditPane;
